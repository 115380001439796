import { render, staticRenderFns } from "./DeliveryNotesTable.vue?vue&type=template&id=5ed52193&scoped=true&"
import script from "./DeliveryNotesTable.vue?vue&type=script&lang=js&"
export * from "./DeliveryNotesTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ed52193",
  null
  
)

export default component.exports