<template>
  <div>
    <BaseTable
      ref="delivery-notes-table"
      resource="delivery-notes"
      :resourceStore="'deliveryNotes'"
      :columns="columns"
      :filters="baseFilters"
      :hide-toolbar="hideToolbar"
      :loading="localLoading || loading"
      :totals-configuration="totalsConfiguration"
      :excluded-filters="baseExcludeFilters"
      :disable-pagination="disablePagination"
      @filter-button-click="filtersVisible = !filtersVisible"
      @on-clear-active-filters="handleClearListFilters"
      @row-clicked="$router.push({ name: 'viewDeliveryNotes', params: { id: $event.id } })"
    >
      <template #cell(provider)="data">
        <span>{{ data.item.provider? data.item.provider.name : '' }}</span>
      </template>
      <template #cell(order)="data">
        <span>{{ data.item.order? data.item.order.number : '' }}</span>
      </template>
      <template #cell(job)="data">
        <span>{{ data.item.job? data.item.job.code : '' }}</span>
      </template>
      <template #cell(purchase_invoice)="data">
        <span>{{ data.item.purchase_invoice.number }}</span>
      </template>
      <template #cell(total_gross)="data">
        <span>{{ data.item.total_gross | numberToLocalString }} €</span>
      </template>
      <template #cell(total_delivery_note)="data">
        <span>{{ data.item.total_delivery_note | numberToLocalString }} €</span>
      </template>
      <template #cell(status)="data">
        <StatusBadge
          v-if="data.item.status.name"
          :status="data.item.status.name"
        />
      </template>
      <template #cell(actions)="{ item }">
        <b-link
          v-if="item.documentation && item.documentation.length > 0"
          class="d-inline-block text-indigo pr-1"
        >
          <feather-icon
            v-b-tooltip.hover
            title="Descargar albaran"
            icon="DownloadIcon"
            size="18"
            @click.stop="handlePrintIconClick(item)"
          />
        </b-link>
        <b-link
          :to="{ name: 'viewDeliveryNotes', params: { id: item.id} }"
          class="pr-1 d-inline-block text-indigo"
        >
          <feather-icon
            v-b-tooltip.hover
            title="Ver"
            icon="EyeIcon"
            size="18"
          />
        </b-link>
        <b-link
          v-access="{
            resource: $data.$constants.RESOURCES.RESOURCE_DELIVERY_NOTES,
            resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
          }"
          class="d-inline-block text-danger"
          @click="handleDeleteIconClick(item)"
        >
          <feather-icon
            v-b-tooltip.hover
            title="Eliminar"
            icon="TrashIcon"
            size="18"
          />
        </b-link>
      </template>
      <template #footer="{ data }">
        <b-tr
          v-if="footerTotalsVisible"
          class="bg-action-blue"
        >
          <b-td colspan="4" />
          <b-td class="text-right text-uppercase font-weight-bold">
            Totales:
          </b-td>
          <b-td class="text-uppercase font-weight-bold">
            {{ data.totals.total_delivery_notes || 0 }}
          </b-td>
          <b-td colspan="1" />
        </b-tr>
      </template>
    </BaseTable>
    <DeliveryNotesListFilters
      v-if="!hideToolbar"
      ref="delivery-notes-list-filters"
      v-model="filtersVisible"
      @filters-submit="$store.commit('deliveryNotes/setFilters', $event)"
    />
  </div>
</template>

<script>
import ApiRestService from '@/api/base-api'
import BaseTable from '@/components/ui/table/BaseTable.vue'
import StatusBadge from '@/components/status/badge/StatusBadge.vue'
import DeliveryNotesListFilters from '@/components/delivery-notes/filters/DeliveryNotesListFilters.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'DeliveryNotesTable',
  components: {
    DeliveryNotesListFilters,
    StatusBadge,
    BaseTable,
  },
  props: {
    purchaseInvoiceId: {
      type: String,
      default: null,
    },
    orderId: {
      type: String,
      default: null,
    },
    providerId: {
      type: Number,
      default: null,
    },
    hideToolbar: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    footerTotalsVisible: {
      type: Boolean,
      default: false,
    },
    totalsConfiguration: {
      type: Array,
      default: () => [
        { colspan: 5 },
        { label: 'TOTAL', key: 'total_delivery_notes' },
        { colspan: 2 },
      ],
    },
  },
  data() {
    return {
      localLoading: false,
      filtersVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      filters: 'deliveryNotes/getFilters',
    }),
    baseFilters() {
      return {
        provider_id: this.providerId, purchase_invoice_id: this.purchaseInvoiceId, order_id: this.orderId, ...this.filters,
      }
    },
    baseExcludeFilters() {
      const excludedFilters = []
      if (this.providerId) {
        excludedFilters.push('provider_id')
      }

      if (this.purchaseInvoiceId) {
        excludedFilters.push('purchase_invoice_id')
      }

      if (this.orderId) {
        excludedFilters.push('order_id')
      }

      return excludedFilters
    },
    columns() {
      let result = []
      if (!this.providerId) {
        result.push(
          {
            label: 'PROVEEDOR',
            key: 'provider',
            sortable: true,
            thStyle: { 'min-width': '100px' },
            thClass: 'px-2',
            tdClass: 'px-2',
          },
        )
      }

      if (!this.orderId) {
        result.push(
          {
            label: 'Nº PEDIDO',
            key: 'order',
            sortable: true,
          },
        )
      }

      result = result.concat([
        {
          label: 'JOB',
          key: 'job',
          sortable: true,
          thStyle: { 'min-width': '100px' },
        },
        {
          label: 'Nº ALBARÁN',
          key: 'number',
          sortable: true,
          thStyle: { 'min-width': '100px' },
        },
      ])

      if (!this.purchaseInvoiceId) {
        result.push(
          {
            label: 'Nº FACTURA',
            key: 'purchase_invoice',
            sortable: true,
          },
        )
      }
      return result.concat([
        {
          label: 'T. ALBARÁN',
          key: 'total_delivery_note',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: true,
          thStyle: { 'min-width': '120px' },
        },
        {
          label: 'ESTADO',
          key: 'status',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          label: 'ACCIONES',
          key: 'actions',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { 'min-width': '210px' },
        },
      ])
    },
  },
  methods: {
    loadData() {
      this.$refs['delivery-notes-table'].loadData()
    },
    handleClearListFilters() {
      this.$store.commit('deliveryNotes/setFilters', {})
      this.$refs['delivery-notes-list-filters'].clearFilters()
    },
    async handlePrintIconClick(deliveryNotes) {
      if (!deliveryNotes.documentation || deliveryNotes.documentation.length === 0) {
        return
      }

      this.localLoading = true
      try {
        const file = deliveryNotes.documentation[0]
        await ApiRestService.downloadArchive(file.id, file.name)
      } finally {
        this.localLoading = false
      }
    },
    async handleDeleteIconClick(deliveryNotes) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: `Se borrará el registro Nº${deliveryNotes.number}.`,
      })

      if (!response.isConfirmed) {
        return
      }

      this.localLoading = true
      try {
        await ApiRestService.delete(deliveryNotes.id, this.$data.$constants.RESOURCES.RESOURCE_DELIVERY_NOTES)
        await this.loadData()
      } finally {
        this.localLoading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
